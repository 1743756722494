<template>
  <v-row class="justify-center">
    <v-btn-toggle
      v-model="view"
      color="secondary"
      group
      mandatory
    >
      <v-btn
        v-if="!show3dviewer"
        :value="views.slides"
        class="orange--text text--darken-3"
        large
      >
        <v-icon>view_carousel</v-icon>
      </v-btn>
      <v-btn
        v-if="!show3dviewer"
        :value="views.tiles2"
        class="orange--text text--darken-3"
        large
      >
        <v-icon>border_all</v-icon>
      </v-btn>
      <v-btn
        v-if="!show3dviewer"
        :value="views.tiles3"
        class="orange--text text--darken-3"
        large
      >
        <v-icon>grid_on</v-icon>
      </v-btn>
      <v-btn
        v-if="!show3dviewer"
        :value="views.video"
        class="orange--text text--darken-3"
        large
      >
        <v-icon>slideshow</v-icon>
      </v-btn>
      <v-btn
        v-if="show3dviewer"
        :value="views.viewer3d"
        class="orange--text text--darken-3"
        large
      >
        <v-icon>3d_rotation</v-icon>
      </v-btn>
      <v-btn
        v-if="showaudio"
        :value="views.audio"
        class="orange--text text--darken-3"
        large
      >
        <v-icon>audiotrack</v-icon>
      </v-btn>
      <v-btn v-if="showavatar"
             :value="views.viewer3d"
             class="orange--text text--darken-3"
      >
        <v-icon>account_box</v-icon>
      </v-btn>
      <v-btn
        :value="views.metaperson3DViewer"
        class="orange--text text--darken-3 gap-0"
        large
      >
        <v-icon>accessibility</v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-row>
</template>

<script>
import capturesService from '../../js/services/capturesService';

export default {
  name:  'CaptureDetailsView',
  props: {
    show3dviewer: {
      type:    Boolean,
      default: false,
    },
    showaudio: {
      type:    Boolean,
      default: false,
    },
    showavatar: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      view:     null,
      views:    capturesService.views,
      viewMode: 0,
    };
  },
  watch: {
    view() {
      this.$emit('view-changed', this.view);
    },
  },
  mounted() {
    this.view = this.show3dviewer ? capturesService.views.viewer3d
      : capturesService.views.slides;
  },
};
</script>
<style lang="scss" module>
@import "../../css/variables";

.root {
  margin-top: 0px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
<style lang="scss" scoped>
@import "../../css/variables";

.active {
  background-color: $grey-1;
}
</style>
