<template>
  <div
    :class="$style.root"
  >
    <div style="height: 45vw; width: 100%; position: relative;">
      <iframe
        id="editor_iframe"
        src="https://metaperson.avatarsdk.com/iframe.html?logoUrl=https://media.licdn.com/dms/image/v2/C4E0BAQH3TeVK0uEc_w/company-logo_200_200/company-logo_200_200/0/1630630458990/oviotech_logo&logoWidth=300&logoHeight=300"
        allow="fullscreen"
        frameborder="0"
        :style="{
          opacity: loaded ? 1 : 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
        }"
      />
      <div v-if="!loaded"
           :class="$style.loadingContainer"
      >
        <video
          id="editor_video"
          :src="videoUrl"
          :controls="false"
          :class="$style.editorVideo"
          autoplay
          loop
          muted
        />
        <div :class="$style.loadingMessage">
          <h2>Generating Avatar</h2>
          <v-progress-linear indeterminate />
          <div :class="$style.timeRemaining">
            Estimated time remaining: &lt; 1 minute
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from 'vee-validate/dist/rules';
import config from '../../js/config';
import accountsService from '../../js/services/accountsService';
import capturesService from '../../js/services/capturesService';

export default {
  name:  'CaptureDetailsMetapersonCreator',
  props: {
    accountId: {
      type:    Number,
      default: 0,
      required,
    },
    captureId: {
      type:    Number,
      default: 0,
      required,
    },
  },
  data() {
    return {
      loaded:   false,
      videoUrl: '',
    };
  },
  async mounted() {
    window.addEventListener('message', this.onWindowMessage);
    this.$data.videoUrl = await capturesService.getVideoSrc(this.$props.captureId);
  },
  methods: {
    onWindowMessage(evt) {
      if (evt.type === 'message') {
        if (evt.data?.source === 'metaperson_creator') {
          const { data } = evt;
          const evtName = data?.eventName;

          // eslint-disable-next-line no-console
          console.log('Metaperson event received: ', evt.data?.eventName, evt);

          switch (evtName) {
            case 'metaperson_creator_loaded':
              this.configureUI(evt);
              this.authenticate(evt);

              break;

            case 'authentication_status':
              this.exportParameters(evt);
              this.generateAvatar(evt);
              break;

            case 'action_availability_changed':
              break;

            case 'model_generated':
              setTimeout(() => {
                this.loaded = true;
              }, 7500);
              break;

            default:
              break;
          }
        }
      }
    },
    authenticate(evt) {
      const authenticationMessage = {
        eventName:    'authenticate',
        clientId:     '9nSV7xaN9k46VFnMg7qrBAsf3tAygj0SAdT4L74u',
        clientSecret: 'al2FxMKwDP3OyAbhRGe2bXSaP8HIZekWFswludhRVDnywWoDYkd7fUdKwpjwSK8tnib6ia831DwTGYYz7TVfv9GQcd7U8Tym50FT0x0jyKd8zIJzSyukwFt3SGkPKR65',
      };

      evt.source.postMessage(authenticationMessage, 'https://metaperson.avatarsdk.com');
    },
    async fetchImageAsBase64(url) {
      const response = await fetch(url, {
        credentials: 'include',
      });
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async generateAvatar(evt) {
      if (!evt.source) {
        // eslint-disable-next-line no-console
        console.error('Sender is not defined.');

        return;
      }

      const captureId = window.location.pathname.split('/').pop();
      const [ account, sourceImage ] = await Promise.all(
        [ accountsService.getAccountInfo(this.$props.accountId),
          this.fetchImageAsBase64(`${config.apiUrl}api/captures/${captureId}/images?angle=89&width=2048&height=2048`) ],
      );

      let gender;

      if (account.fields.gender) {
        gender = account.fields.gender === '1' ? 'male' : 'female';
      }

      const generateAvatarMessage = {
        gender,
        eventName: 'generate_avatar',
        age:       'adult',
        image:     sourceImage,
      };

      evt.source.postMessage(generateAvatarMessage, '*');
    },

    configureUI(evt) {
      const uiParametersMessage = {
        // Common parameters for Mobile and Desktop versions
        eventName:             'set_ui_parameters',
        isExportButtonVisible: true,
        isLoginButtonVisible:  false,
        outfitsBlackList:      [ 'ARPI', 'SEVAN' ],

        // Desktop version specific parameters
        closeExportDialogWhenExportCompleted: false,
        isLanguageSelectionVisible:           false,
        language:                             '',
        showLatestCreatedAvatar:              false,
        metaPersonLabelText:                  'MetaPerson Ovio Avatars',
        isTakeSelfieButtonVisible:            false,
        isBrowsePhotoButtonVisible:           false,
        showSampleAvatars:                    false,
      };

      evt.source.postMessage(uiParametersMessage, 'https://metaperson.avatarsdk.com');
    },
    exportParameters(evt) {
      const exportParametersMessage = {
        eventName:      'set_export_parameters',
        format:         'gltf',
        lod:            2,
        textureProfile: '4K.png',
        useZip:         true,
      };

      evt.source.postMessage(exportParametersMessage, 'https://metaperson.avatarsdk.com');
    },
  },
};
</script>
<style lang="scss" module>
  @import '../../css/variables';

  .root {
    background-color: #000000;
  }

  .lowerHeader {
    white-space:nowrap;
  }

  .editorVideo {
    position: absolute;
    width: 45vw;
    height: 45vw;
    max-width: 612px;
  }

  .loadingContainer {
    width: 1200px;
    height: 700px;
    position: relative;
    margin: auto;
  }

  .loadingMessage {
    margin-top: 10vw;
    color: #FFFFFF;
    position: absolute;
    width: 300px;
    right: 200px;
    top: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 2rem;
    }

    .v-progress-linear {
      width: 90%;
    }

    .timeRemaining {
      margin-top: 1.5rem;
    }
  }
</style>
